<template>
  <div>
    <Head :head-info="HeadInfo" @headBack="goBack"></Head>
    <div class="phone-coupon">
      <template v-for="item in couponList">
        <div class="inner">
          <img
            :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_coupon_${item.active ? 'active' : 'inactive'}.png`">
          <div class="coupon-info">
            <p :class="[item.active ? '' : 'inactive', 'coupon-value']">¥
              <span>{{ item.couponValue }}</span></p>
            <div class="use-desc">
              <p>满100元可用</p>
              <p>限{{ item.startTime }}使用</p>
            </div>
            <van-button :class="item.active ? '' : 'inactive'"
                        @click="handleCouponOperate(item)">{{ item.statusDesc }}
            </van-button>
          </div>
        </div>
      </template>
      <van-action-sheet v-model="rechargePop" :round="false" :close-on-click-overlay="false">
        <div class="coupon-pop">
          <p class="title">100元话费券权益待领取</p>
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_coupon_service.png">
          <van-button @click="handlePay">9.9元领取100元话费券</van-button>
        </div>
      </van-action-sheet>
      <van-dialog v-model="dialogVisible" :showConfirmButton="false" style="background-color: transparent;">
        <div class="wrapper">
          <p>支付是否成功</p>
          <p>请确认支付状态，领取话费券</p>
          <van-button class="success-button" round @click="handleReload">支付成功</van-button>
          <van-button class="fail-button" plain round @click="handleReload">支付失败</van-button>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, ActionSheet, Toast, Dialog } from 'vant'
import baseUrl from '@/utils/baseUrl'
import { timeYear } from '@/utils/utils'
import Head from '../../components/fullWebviewHead.vue'

const api = require('@/utils/api.js').api

Vue.use(Button)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(ActionSheet)
export default {
  name: 'phoneCoupon',
  data () {
    return {
      // 话费券
      couponList: [],
      // 弹出
      rechargePop: false,
      // dialog显示
      dialogVisible: false,
      click: true,
      HeadInfo: {
        title: '话费券'
      }
    }
  },
  components: {
    Head
  },
  created () {
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    if (this.$store.state.isAndroidApp) {
      this.$h5AppLink('updateStyle', {
        statusBarBgColor: 'white',
        statusBarTextColor: 'black'
      })
    }
    this.handleCheckRight()
    this.handleGetCouponList()
  },
  methods: {
    /*
    登录验证
     */
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/phone/coupon`
      }
      this.$h5AppLink(event, param)
    },
    /*
    话费券列表
     */
    handleGetCouponList () {
      this.$axios(api.couponInfoList, {}).then(res => {
        this.couponList = res.data.data
        this.couponList ? this.couponList.map(item => {
          item.startTime = item.startTime ? timeYear(item.startTime).substr(0, 8) : ''
          item.active = item.status == -1 || item.status == 0 || item.status == 2 || item.status == 3 ? false : true
        }) : []
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },

    /*
    券点击操作
     */
    handleCouponOperate (data) {
      if (data.status == 1) {
        this.$axios(api.couponUsedLock, {
          params:{
            couponId: data.id
          }
        }).then(res => {
          this.$h5AppLink('openWebview', {
            url: res.data.data.rechargeUrl
          })
        }).catch(err => {
          Toast.fail(err.data.message)
          console.info(err)
        })
      } else if (data.status == -1) {
        Toast.fail('该券已经锁定')
      } else if (data.status == 0) {
        Toast.fail('该券未到时间')
      } else if (data.status == 2) {
        Toast.fail('该券已使用')
      } else if (data.status == 3) {
        Toast.fail('该券已过期')
      }
    },

    /*
    查看活动权益
    */
    handleCheckRight(){
      this.$axios({
        url: api.luckyDrawRights,
        method: 'get',
        params: {}
      }).then(res => {
        this.rechargePop = !res.data.data.drawAccess
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },

    /*
    立即支付
     */
    handlePay () {
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios({
        url: api.wxPay,
        method: 'post',
        params: {
          tradeType: 4,
          bizType: 5,
          platform: 1
        }
      }).then(res => {
        this.click = true
        this.dialogVisible = true
        window.location.href = res.data.data.mwebUrl
      }).catch(err => {
        this.click = true
        this.isLogin()
        console.info(err)
      })
    },
    /*
    支付成功刷新页面
    */
    handleReload () {
      this.$router.go(0)
    },
    /*
    顶部返回按钮
     */
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.van-overlay{
  background: #FFFFFF;
}
.van-popup{
  overflow: visible;
  .van-action-sheet__content{
    overflow: visible;
  }
}
.phone-coupon {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  text-align: center;
  padding-top: 12px;

  .inner {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;

    & > img {
      width: 360px;
      height: 90px;
    }

    .coupon-info {
      position: absolute;
      top: 16px;
      width: 100%;
      display: flex;
      align-items: center;

      .coupon-value {
        margin-left: 16px;
        font-size: 20px;
        font-weight: 800;
        color: #FE4949;
        line-height: 28px;

        & > span {
          font-size: 40px;
          line-height: 56px;
        }
      }

      .use-desc {
        margin-left: 40px;

        & > p:first-child {
          font-size: 15px;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
        }

        & > p:last-child {
          font-size: 13px;
          font-weight: 400;
          color: #999999;
          line-height: 18px;
        }
      }

      .van-button {
        margin-left: 50px;
        width: 90px;
        height: 40px;
        background: #FFEA1C;
        border-radius: 20px;
        border: none;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
      }

      .inactive {
        background: #E2E2E2 !important;
        color: #999999 !important;
      }
    }
  }

  .coupon-pop {
    height: 650px;
    position: relative;
    .title{
      position: absolute;
      top: -44px;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #C99040;
      line-height: 28px;
    }
    & > img {
      width: 302px;
      height: 166px;
    }
    .van-button{
      margin-top: 50px;
      width: 300px;
      height: 50px;
      background: #FFEA1C;
      box-shadow: 0px 3px 5px 1px rgba(255, 234, 28, 0.5);
      border-radius: 25px;

      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 22px;
    }
  }

  .wrapper{
    position: relative;
    display: inline-block;
    width: 320px;
    height: 250px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;
    p:first-child{
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }
    p{
      font-size: 15px;
      color: #7F694E;
      line-height: 21px;
    }
    .van-button{
      margin-top: 20px;
      width: 260px;
      height: 50px;
      border-radius: 25px;
      font-size: 18px;
      color: #7F694E;
      line-height: 25px;
    }
    .success-button{
      background: #FFEA1C;
      font-weight: bold;
      color: #333333;
      border: none;
    }
    .fail-button{
      border: 1px solid #7F694E;
    }
  }
}
</style>
